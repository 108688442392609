//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: "HomeView",
  data() {
    return {
      list: [],
      loading: false,
      totalCounts: 0
    };
  },
  mounted() {

    window.document.title = "全员营销";

  },
  activated() {
    this.getList()
  }
  ,
  methods: {
    getList() {
      this.loading = false
      this.list = [];
      this.axios
        .get(
          "/" + this.$ajaxPrefix.consumer +
          "/v1/wx/cp/crm/customer/getMemberBindHr",
        )
        .then((res) => {

          console.log(res.data);
          if (res && res.data && res.data.data && res.data.data.length > 0) {
            this.totalCounts = res.data.data.length;
            this.rebuildData(res.data.data);

          }
          this.loading = true
        });
    },
    goPage() {
      this.$router.push('/staffsale/mygrey')
    },
    hideMobile(str) {
      var reg = /^(\d{3})\d{4}(\d{4})$/;
      return str.replace(reg, "$1****$2");
    },
    rebuildData(list) {
      let listTemp = [];
      list.map((item) => {
        if (item.erdat) {
          let year = this.$moment(item.erdat).format('YYYY');
          let has = false;
          listTemp.map((tempItem) => {
            if (tempItem.year == year) {
              tempItem.data.push(item)
              tempItem.data=tempItem.data.sort((a,b)=>{
                return Number(b.erdat)-Number(a.erdat)
              })
      
              has = true
            }
          })
          if (has == false) {
            listTemp.push({
              year: year,
              data: [item],
              show:false
            })
          }
        }

      })
      console.log('listTemp', listTemp)
      this.list = listTemp.sort((a, b) => {
        return b.year - a.year
      });
      this.list[0].show=true
    },
    formatDate(str) {
      return this.$moment(str).format('YYYY-MM-DD');
    },
    switchSlide(item){
        item.show=!item.show
    }
  }
}
